export default [
    {
        path: "/default-email-templates",
        meta: { roles: [1] },
        redirect: "/default-email-templates/all",
        component: () => import("src/components/Dashboard/Layout/DashboardLayout.vue"),
        children: [
            {
                path: "all",
                name: "AllDefaultEmailTemplates",
                meta: {
                  pageTitle: 'Email template mặc định'
                },
                component: () => import("src/components/Dashboard/Views/DefaultEmailTemplate/All-Templates.vue"),
            },
            {
                path: ":id",
                name: "EditDefaultEmailTemplates",
                meta: {
                  pageTitle: 'Cập nhật'
                },
                component: () => import("src/components/Dashboard/Views/DefaultEmailTemplate/Edit-Template.vue"),
            }
        ]
    }
  ];
