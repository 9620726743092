import axios from './../api';
import { buildQuery } from "../actions"
export default {
  namespaced: true,
  state: {
    groups: [],
    groupDetail: [],

    partners: [],
    partnerDetail: [],

    commissions: [],

    withdrawals: [],
    withdrawalDetail: []
  },

  mutations: {
    GROUPS(state, val) {
      state.groups = val;
    },

    PARTNERS(state, val) {
      state.partners = val;
    },

    COMMISSIONS(state, val) {
      state.commissions = val;
    },

    WITHDRAWALS(state, val) {
      state.withdrawals = val;
    },

    GROUP_DETAIL(state, val) {
      state.groupDetail = val;
    },

    PARTNER_DETAIL(state, val) {
      state.partnerDetail = val;
    },

    WITHDRAWAL_DETAIL(state, val) {
      state.withdrawalDetail = val;
    }

  },

  actions: {
    async fetchGroup(ctx, params) {
      try {
        return await axios.get('/api/admin/affiliate/groups' + buildQuery(params));
      } catch (error) {
        console.log(error);
      }
    },

    async fetchCommission(ctx, params) {
      try {
        return await axios.get('/api/admin/affiliate/commissions' + buildQuery(params));
      } catch (error) {
        console.log(error);
      }
    },

    async fetchWithdrawals(ctx, params) {
      try {
        return await axios.get('/api/admin/affiliate/withdrawals' + buildQuery(params));
      } catch (error) {
        console.log(error);
      }
    },

    async fetchPartner(ctx, params) {
      try {
        return await axios.get('/api/admin/affiliate/partners' + buildQuery(params));
      } catch (error) {
        console.log(error);
      }
    },

    groupDetail(ctx, id) {
      let { commit, state } = ctx;
      axios.get('/api/admin/affiliate/groups/' + id)
        .then(response => {
          commit('GROUP_DETAIL', response.data.data);
        });
    },

    partnerDetail(ctx, id) {
      let { commit, state } = ctx;
      axios.get('/api/admin/affiliate/partners/' + id)
        .then(response => {
          commit('PARTNER_DETAIL', response.data.data);
        });
    },

    withdrawalDetail(ctx, id) {
      let { commit, state } = ctx;
      axios.get('/api/admin/affiliate/withdrawals/' + id)
        .then(response => {
          commit('WITHDRAWAL_DETAIL', response.data.data);
        });
    },

    storeGroup(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/admin/affiliate/groups', data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    storePartner(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/admin/affiliate/partners', data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    updateGroup(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/admin/affiliate/groups/' + data.id, data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    updatePartner(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/admin/affiliate/partners/' + data.id, data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    updateWithdrawal(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/admin/affiliate/withdrawals/' + formData.get('id'), formData)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    deleteGroup(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete('/api/admin/affiliate/groups/' + id)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    deletePartner(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete('/api/admin/affiliate/partners/' + id)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    searchUser(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/admin/affiliate/partners/users/search/' + id)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    async fetchCommissionByUser(ctx, params) {
      try {
        return await axios.get('/api/admin/affiliate/partner-commissions/'+ buildQuery(params));
      } catch (error) {
        console.log(error);
      }
    },

    sendEmailWithdraw(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/admin/affiliate/withdrawals/send-email/' + id)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    }

  }
}
