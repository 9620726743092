import axios from './../api';
import { buildQuery } from "../actions";

export default {
    namespaced: true,

    state: {
        catalogs: [],
        catalog_detail: []
    },

    mutations: {
        CATALOGS(state, val) {
            state.catalogs = val;
        },

        CATALOG_DETAIL(state, val) {
            state.catalog_detail = val;
        }
    },

    getters: {

        catalogs(state) {
            for (let item of state.catalogs) {
                item.sport_type_name = item.sport_type_id ? item.sport_type.name : '';
            }
            return state.catalogs;
        },

        catalog_detail: state => state.catalog_detail,

        units() {
            return [
                {
                    value: "km",
                    label: "km"
                },
                {
                    value: "step",
                    label: "bước chân"
                },
                {
                    value: "times",
                    label: "cái"
                },
                {
                    value: "day",
                    label: "ngày"
                }
            ]
        }
    },

    actions: {

        fetchCatalogs(ctx, payload) {
            let {commit, state} = ctx;
            axios.get('/api/admin/catalogs', { params: payload })
            .then(response => {
                commit('CATALOGS', response.data.data);
            })
            .catch(error => {
                console.error('fetchCatalogs: ', error);
            });
        },

        getCatalog(ctx, id) {
            let { commit, state } = ctx;
            axios.get('/api/admin/catalogs/' + id)
                .then(response => {
                    commit('CATALOG_DETAIL', response.data.data);
                })
                .catch(error => {
                    console.error('fetchEventDetail: ', error);
                });
        },

        storeCatalog(ctx, obj) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/api/admin/catalogs`, obj)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
                });
        },

        updateCatalog(ctx, obj) {
            return new Promise((resolve, reject) => {
                axios
                .put(`/api/admin/catalogs/${obj.id}` , obj)
                .then(response => resolve(response.data))
                .catch(error => reject(error))
            });
        },

        destroyCatalog(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/api/admin/catalogs/${id}`)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
                });
        }

    },
}

