// users
export const AUTH_REQUEST = 'AUTH_REQUEST'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_ERROR = 'AUTH_ERROR'
export const LOGOUT = 'LOGOUT'

// users
export const FETCH_USERS = 'FETCH_USERS'
export const FETCH_USER_DETAIL = 'FETCH_USER_DETAIL'

export const FETCH_STAFF_DETAIL = 'FETCH_STAFF_DETAIL'

export const FETCH_ACTIVITIES = 'FETCH_ACTIVITIES'


//settings
export const TOGGLE_LOADING = 'TOGGLE_LOADING'
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
export const FETCH_ALL_SETTING = 'FETCH_ALL_SETTING'
export const FETCH_ALL_CACHING = 'FETCH_ALL_CACHING'

export const FETCH_EXERCISES = 'FETCH_EXERCISES'

export const FETCH_ORDER_PRODUCT_DETAIL = 'FETCH_ORDER_PRODUCT_DETAIL'

export const FETCH_TICKET_DETAIL = 'FETCH_TICKET_DETAIL'

export const FETCH_EXERCISE_DETAIL = 'FETCH_EXERCISE_DETAIL'

export const FETCH_USER_EMAIL_STATUS = 'FETCH_USER_EMAIL_STATUS'

export const FETCH_PROMOTION_TYPES = 'FETCH_PROMOTION_TYPES'

export const FETCH_PROMOTION_DETAIL = 'FETCH_PROMOTION_DETAIL'

export const FETCH_PRODUCT_CATEGORIES = 'FETCH_PRODUCT_CATEGORIES'

export const FETCH_ITEMS = 'FETCH_ITEMS'

export const FETCH_MAIL_TYPES = 'FETCH_MAIL_TYPES'

export const FETCH_PRODUCT_CATEGORY_DETAIL = 'FETCH_PRODUCT_CATEGORY_DETAIL';

export const FETCH_PROVINCES = 'FETCH_PROVINCES'
export const FETCH_DISTRICTS = 'FETCH_DISTRICTS'
export const FETCH_WARDS = 'FETCH_WARDS'

export const FETCH_SHIPPING_STATUS = 'FETCH_SHIPPING_STATUS'
export const FETCH_SHIPPING_CONFIG = 'FETCH_SHIPPING_CONFIG'

export const FETCH_SEND_MAIL_HISTORY = 'FETCH_SEND_MAIL_HISTORY'

export const FETCH_GROUP_CLUBS = 'FETCH_GROUP_CLUBS'

export const FETCH_ACTIVITY_DETAIL = 'FETCH_ACTIVITY_DETAIL'

export const FETCH_ALL_ENTERPRISE_RACES = 'FETCH_ALL_ENTERPRISE_RACES'
export const FETCH_ORDER_ENTERPRISE_DETAIL = 'FETCH_ORDER_ENTERPRISE_DETAIL'

// charities
export const FETCH_CHARITIES = 'FETCH_CHARITIES'
export const FETCH_CHARITY_DETAIL = 'FETCH_CHARITY_DETAIL'

export const FETCH_PACKAGES = 'FETCH_PACKAGES'
export const FETCH_PACKAGE_DETAIL = 'FETCH_PACKAGE_DETAIL'

export const FETCH_ORDER_ENTERPRISES = 'FETCH_ORDER_ENTERPRISES'

export const FETCH_ALL_ACTIVITIES_CHEAT = 'FETCH_ALL_ACTIVITIES_CHEAT'
export const FETCH_ALL_DELETED_ACTIVITIES = 'FETCH_ALL_DELETED_ACTIVITIES'

export const FETCH_WORKOUTS = 'FETCH_WORKOUTS'
export const FETCH_WALKING = 'FETCH_WALKING'
export const FETCH_RUNNING = 'FETCH_RUNNING'

// races
export const FETCH_RACES = 'FETCH_RACES'
export const FETCH_ALL_RACES = 'FETCH_ALL_RACES'
export const FETCH_ALL_RACES_SHORT = 'FETCH_ALL_RACES_SHORT'
export const FETCH_RACE_DETAIL = 'FETCH_RACE_DETAIL'
export const FETCH_RACE_TYPES = 'FETCH_RACE_TYPES'
export const FETCH_RACE_CATEGORIES = 'FETCH_RACE_CATEGORIES'
export const FETCH_RACE_CATALOGS = 'FETCH_RACE_CATALOGS'
export const FETCH_RACE_ADDONS = 'FETCH_RACE_ADDONS'

// order
export const FETCH_ORDERS = 'FETCH_ORDERS'
export const FETCH_ORDER_DETAIL = 'FETCH_ORDER_DETAIL'
export const RACE_ID = 'RACE_ID'
export const FETCH_DONATES = 'FETCH_DONATES'


export const FETCH_TICKETS = 'FETCH_TICKETS'

export const FETCH_ORDER_PRODUCTS = 'FETCH_ORDER_PRODUCTS'
export const FETCH_METAFIELD_OBJECT = 'FETCH_METAFIELD_OBJECT'

export const FETCH_ORDER_VIPS = 'FETCH_ORDER_VIPS'
export const FETCH_ORDER_VIP_DETAIL = 'FETCH_ORDER_VIP_DETAIL'

export const FETCH_BILLS = 'FETCH_BILLS'
export const FETCH_ALL_BILLS = 'FETCH_ALL_BILLS'

export const FETCH_CUSTOMER = 'FETCH_CUSTOMER'

export const FETCH_SETTING = 'FETCH_SETTING'

// menu
export const FETCH_MENUS = 'FETCH_MENUS'
export const FETCH_MENU_DETAIL = 'FETCH_MENU_DETAIL'
export const REMOVE_MENU = 'REMOVE_MENU'

// user
export const STORE_USER = 'STORE_USER'
export const EDIT_USER = 'EDIT_USER'

export const FETCH_HISTORY = 'FETCH_HISTORY'
export const FETCH_SHIPPING = 'FETCH_SHIPPING'
export const FETCH_ROLE = 'FETCH_ROLE'
export const FETCH_DELETED = 'FETCH_DELETED'
export const MESSAGE = 'MESSAGE'
export const SET_CURRENT_ACTIONS =  'SET_CURRENT_ACTIONS'
export const SET_CURRENT_FILTER_ORDERS = 'SET_CURRENT_FILTER_ORDERS'
export const SET_CURRENT_FILTER_USERS = 'SET_CURRENT_FILTER_USERS'
export const SET_CURRENT_FILTER_USER_EMAILS = 'SET_CURRENT_FILTER_USER_EMAILS'
export const SET_FILTER_TAGS = 'SET_FILTER_TAGS'
export const SET_PAGE_TITLE =  'SET_PAGE_TITLE'

//addon
export const FETCH_ADDONS =  'FETCH_ADDONS'
export const STORE_ADDON =  'STORE_ADDON'
export const REMOVE_ADDON =  'REMOVE_ADDON'
export const FETCH_ADDON_DETAIL = 'FETCH_ADDON_DETAIL'

export const REMOVE_CATALOG = 'REMOVE_CATALOG'
export const FETCH_CATALOG_DETAIL = 'FETCH_CATALOG_DETAIL'


//rule
export const FETCH_RULES =  'FETCH_RULES'
export const FETCH_RULE_DETAIL =  'FETCH_RULE_DETAIL'
export const STORE_RULE =  'STORE_RULE'
export const REMOVE_RULE =  'REMOVE_RULE'

export const FETCH_VIPS =  'FETCH_VIPS'
export const FETCH_VIP_DETAIL =  'FETCH_VIP_DETAIL'

export const FETCH_RACE_RULES = 'FETCH_RACE_RULES'

export const FETCH_GROUPS = 'FETCH_GROUPS'
export const FETCH_GROUP_DETAIL = 'FETCH_GROUP_DETAIL'

//theme
export const FETCH_THEMES =  'FETCH_THEMES'
export const FETCH_THEME_DETAIL =  'FETCH_THEME_DETAIL'
export const STORE_THEME =  'STORE_THEME'
export const REMOVE_THEME =  'REMOVE_THEME'

//race type
export const FETCH_RACE_TYPE_DETAIL =  'FETCH_RACE_TYPE_DETAIL'
export const REMOVE_RACE_TYPE =  'REMOVE_RACE_TYPE'

//race cate
export const FETCH_RACE_CATEGORY_DETAIL =  'FETCH_RACE_CATEGORY_DETAIL'
export const REMOVE_RACE_CATEGORY =  'REMOVE_RACE_CATEGORY'

//notifications
export const FETCH_NOTIFICATIONS =  'FETCH_NOTIFICATIONS'
export const STORE_NOTIFICATION =  'STORE_NOTIFICATION'

// coupon
export const FETCH_COUPONS =  'FETCH_COUPONS'
export const FETCH_COUPON_DETAIL =  'FETCH_COUPON_DETAIL'
export const STORE_COUPON =  'STORE_COUPON'

// club
export const FETCH_CLUBS = 'FETCH_CLUBS'
export const FETCH_CLUB_DETAIL = 'FETCH_CLUB_DETAIL'

// location
export const FETCH_LOCATIONS = 'FETCH_LOCATIONS'
export const FETCH_LOCATION_DETAIL = 'FETCH_LOCATION_DETAIL'

// company
export const FETCH_COMPANIES = 'FETCH_COMPANIES'
export const FETCH_COMPANY_DETAIL = 'FETCH_COMPANY_DETAIL'

// school
export const FETCH_SCHOOLS = 'FETCH_SCHOOLS'
export const FETCH_SCHOOL_DETAIL = 'FETCH_SCHOOL_DETAIL'

// mail
export const FETCH_MAILS =  'FETCH_MAILS'
export const FETCH_MAIL_DETAIL =  'FETCH_MAIL_DETAIL'
export const MAIL_DELIVERY_STATUS_DETAIL =  'MAIL_DELIVERY_STATUS_DETAIL'

export const FETCH_MAIL_FORMS =  'FETCH_MAIL_FORMS'

export const FETCH_MAIL_FORM_DETAIL =  'FETCH_MAIL_FORM_DETAIL'

// admin
export const FETCH_ADMINS =  'FETCH_ADMINS'
export const FETCH_ADMIN_DETAIL =  'FETCH_ADMIN_DETAIL'
export const STORE_ADMIN =  'STORE_ADMIN'
export const UPDATE_ADMIN =  'UPDATE_ADMIN'

export const FETCH_DASHBOARD =  'FETCH_DASHBOARD'

export const SIDEBAR_INIT = 'SIDEBAR_INIT'
